import React, { useState, useRef, useEffect } from "react";
import checkboxSelection from "./../../images/checkbox-selection-dropdown.png";
import { useTranslation } from "react-i18next";
import styles from "./dropdownFilter.module.css";
import searchbar from "./../../images/filter-searchBar.png";
import arrowSetting from "./../../images/downarrow-setting.png";

export const TypeFilterDropdown = ({
  columns,
  resetFilter,
  isDropdownOpen,
  handleTypeFilterSearch,
  handleSearchTypeFilter,
  TypefilterRequestBody,
  ResetFilter,
  allReset,
  setAllReset,
}) => {
  const { t } = useTranslation();
  const dropdownRef = useRef(null);

  const [selectedColumns, setSelectedColumns] = useState([]);
  const [isAllSelected, setIsAllSelected] = useState(false);

  const handleCheckboxChange = (column) => {
    setSelectedColumns((prevSelectedColumns) => {
      const updatedColumns = prevSelectedColumns.includes(column)
        ? prevSelectedColumns.filter((item) => item !== column)
        : [...prevSelectedColumns, column];

      handleTypeFilterSearch(updatedColumns);
      setIsAllSelected(updatedColumns.length === columns.length);
      return updatedColumns;
    });
  };

  const handleSelectAllChange = () => {
    if (isAllSelected) {
      setSelectedColumns([]);
      handleTypeFilterSearch([]);
    } else {
      setSelectedColumns(columns);
      handleTypeFilterSearch(columns);
    }
    setIsAllSelected(!isAllSelected);
  };


  const resetAll = () => {
    setSelectedColumns([]);
    setIsAllSelected(false);
    ResetFilter("type");
    resetFilter("type");
  };

  useEffect(() => {
    if (allReset === true) {
      setSelectedColumns([]);
      setIsAllSelected(false);
      ResetFilter();
      setAllReset(false);
    }
  }, [ResetFilter, allReset, setAllReset]);

  return (
    <div className={`${styles.selectionFilterButton} ${styles.typeFilter}`}>
      {isDropdownOpen && (
        <div
          ref={dropdownRef}
          className={`${styles.selectionDropdown} ${styles.typeDropdown}`}
        >
          <div className="selection-container">
            <div className={styles.selectionContainerContent}>
              <label>Type filtersettings</label>
               <img src={arrowSetting} alt="arrow Icon" />
              
            </div>

            <ul>
              <div class={styles.selection}>
                <button
                  onClick={() => resetAll()}
                  class={styles.resetSelection}
                >
                  Reset filter
                </button>
              </div>
              <div className={styles.searchBoxFilter}>
                <img src={searchbar} alt="home icon" />
                <input
                  className={styles.checkInputProjectList}
                  type="text"
                  placeholder="Search for filter options"
                  value={TypefilterRequestBody.current.searchText || ""}
                  onChange={handleSearchTypeFilter}
                />
              </div>
                <div className={styles.mainSelectionLIContent}>
              <div className={styles.selectionLiContent}>
                <li>
                <label>
                   <img
                      src={checkboxSelection}
                      alt="Checkbox Selection Dropdown"
                  />
                  <input
                    type="checkbox"
                    checked={isAllSelected}
                    onChange={handleSelectAllChange}
                  />
                  All
                </label>   
                </li>
                {columns.map((column, index) => (
                  <li key={column}>
                    <label>
                      <img
                        src={checkboxSelection}
                        alt="Checkbox Selection Dropdown"
                      />
                      <input
                        type="checkbox"
                        checked={selectedColumns.includes(column)}
                        onChange={() => handleCheckboxChange(column)}
                      />
                      {column}
                    </label>
                  </li>
                ))}
              </div>
              </div>
            </ul>
          </div>
        </div>
      )}
    </div>
  );
};
