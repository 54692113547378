import React, { useEffect, useState } from "react";
import styles from "./PageUtility.module.css";
import { projectBuildingData } from "../../../projectList/components/api/projectBuidlingData";
import { Circles } from "react-loader-spinner";
import { useTranslation } from "react-i18next";
import { FormatAlignJustify } from "@mui/icons-material";

export const RefreshOverLayFile = ({ countdown, id, pageReload }) => {
  const [processingStatus, setProcessingstatus] = useState();
  const { t } = useTranslation();

  useEffect(() => {
    const interval = setInterval(async () => {
      try {
        const response = await projectBuildingData(id);
        setProcessingstatus(response?.data?.processingStatus);
      } catch (error) {
        console.error(
          `Error updating processing status for building ${id}:`,
          error
        );
      }
    }, 5000);

    return () => clearInterval(interval);
  }, [id]);

  useEffect(() => {
    if (processingStatus === "COMPLETED") {
      const timeout = setTimeout(() => {
        pageReload();
      }, 2000);

      return () => clearTimeout(timeout);
    }
  }, [processingStatus, pageReload]);
  return (
    <div className={styles.overlay}>
      <div className={styles.messageBox}>
        {processingStatus === "COMPLETED" ? (
          <p>Analysis Complete! Realoading the Report</p>
        ) : (
          <>
            <p>Analysis has started again. Please wait</p>
            <div className={styles.completeMessageSpinner}>
              <Circles type="TailSpin" color="#FF6B00" height={40} width={50} />
            </div>
          </>
        )}
      </div>
    </div>
  );
};
