import { useEffect, useRef, useState } from "react";
import { getUser, login } from "../helper/auth_helper";
import jwt_decode from "jwt-decode";

const Protected = (props) => {
  const { Component } = props;

  const isRun = useRef(false);

  const [isAuthenticated, setLoggedIn] = useState(false);
  const [suprmenAdmin, setSuprmenAdmin] = useState(false);
  const [orgAdmin, setOrgAdmin] = useState(false);
  const [viewer, setViewer] = useState(false);
  const [regular, setRegular] = useState(false);

  useEffect(() => {
    if (isRun.current) return;

    isRun.current = true;
    getUser()
      .then((user) => {
        if (!user) {
          setLoggedIn(false);
          login();
        } else {
          setLoggedIn(true);
        }
      })
      .catch((err) => {
        console.log(err);
        login();
      });
  }, []);

  useEffect(() => {
    async function getRole() {
      const user = await getUser();
      if (!user || !user.access_token) {
        return;
      }
      const token = user.access_token;
      const decodedToken = jwt_decode(token);
      const realmAccesssRoles = decodedToken.realm_access.roles;

      if (realmAccesssRoles.includes("SUPRMEN_ADMIN")) {
        setSuprmenAdmin(true);
      }

      if (realmAccesssRoles.includes("ORG_ADMIN")) {
        setOrgAdmin(true);
      }
      if (realmAccesssRoles.includes("VIEWER")) {
        setViewer(true);
      }

      if (realmAccesssRoles.includes("REGULAR")) {
        setRegular(true);
      }
    }

    getRole();
  }, []);

  return isAuthenticated ? (
    <Component
      suprmenAdmin={suprmenAdmin}
      viewer={viewer}
      regular={regular}
      orgAdmin={orgAdmin}
    />
  ) : (
    <div>Redirecting to login page...</div>
  );
};

export default Protected;
