import styles from "./PageUtility.module.css";
import { ProtectedSidebar } from "../../../sidebar/protectedSidebar";
import hamburger from "../../../../images/hamburger.jpg";

export const PageUtility = () => {
  return (
    <>
        <input id="slide-sidebar" type="checkbox" role="button" />
                <label for="slide-sidebar">
                  <span>
                    <i class="fa fa-bars">
                      <img
                        className="sidebar-toggle"
                        alt="sidebar"
                        src={hamburger}
                      ></img>
                    </i>
                  </span>
                </label>
                <div class="sidebar">
                  <div class="sidebar-content">
                    <div>
                      <ProtectedSidebar />
                    </div>
                  </div>
                </div>
    </>
  );
};
