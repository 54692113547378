import React, { useState, useRef, useEffect } from "react";
import columnSelection from "./../../images/column-selection.png";
import columnSelectionDropdown from "./../../images/column-selectionDropdown.png";
import checkboxSelection from "./../../images/checkbox-selection-dropdown.png";
import arrowSetting from "./../../images/downarrow-setting.png";
import { useTranslation } from "react-i18next";
import styles from "./dropdownFilter.module.css";

export const DropdownFilter = ({
  columns,
  selectedColumns,
  onToggleColumn,
  handleResetColumns,
}) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const dropdownRef = useRef(null);
  const buttonRef = useRef(null);
  const { t } = useTranslation();

  const columnDisplayNames = {
    orgName: t("organisationName_table"),
    stad: t("city_table"),
    startDate: t("startDate_table"),
    endDate: t("endDate_table"),
    userName: t("userName_table"),
    email: t("email_table"),
    role: t("role_table"),
    organisation: t("organisation_table"),
    status: t("status_table"),
    projectName: t("projectName_table"),
    clientName: t("clientName_table"),
    expectedDeliveryDate: t("expectedDeliveryDate_table"),
    quoteStatus: t("quoteStatus_table"),
    actions: t("action"),

    elementName: "Element Name",
    type: "Type",
    location: "Location",
    length: "Length",
    area: "Area",
    quantity: "Quantity",
    grossTotal: "Gross Total",
    netTotal: "Net Total",
    viewIn3D: "View In 3D",
    lengthTotal: "Length Total",
  };

  const handleButtonClick = () => {
    setIsDropdownOpen((prev) => !prev);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target) &&
        buttonRef.current &&
        !buttonRef.current.contains(event.target)
      ) {
        setIsDropdownOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className="main-column-selection">
      <button
        ref={buttonRef}
        onClick={handleButtonClick}
        className={`selection-button ${isDropdownOpen ? 'activebackgroundColor' : ''}`}
      >
         {!isDropdownOpen && <img src={columnSelection} alt="Column Selection Icon" />}
         {isDropdownOpen && <img src={columnSelectionDropdown} alt="Column Selection Dropdown  Icon" />}

      </button>
      {isDropdownOpen && (
        <div ref={dropdownRef} className="selection-dropdown">
          {/* Container for overlapping sections */}
          <div className="selection-container">
            <div className="selection-container-content">
              <label>My Column Settings</label>
              <img src={arrowSetting} alt="arrow Icon" />
            </div>

            {/* Main content of the dropdown */}
            <ul>
              <div class="selection">
                <button
                  onClick={() => handleResetColumns()}
                  class="btn btn-success reset-selection"
                >
                  {t("resetToDefault")}
                </button>
              </div>
              <div className={styles.selectionLiContent}>
                {columns.map((column, index) => (
                  <li key={column}>
                    <label>
                      <img
                        src={checkboxSelection}
                        alt="Checkbox Selection Dropdown Image"
                      />
                      <input
                        type="checkbox"
                        checked={selectedColumns[column]}
                        onChange={() => onToggleColumn(column)}
                      />
                      {columnDisplayNames[column]}
                    </label>
                  </li>
                ))}
              </div>
            </ul>
          </div>
        </div>
      )}
    </div>
  );
};
