import { getUser } from "../../../helper/auth_helper";
import api from "../../../helper/auth_interceptor";

export async function fetchJsonReportData(
  id,
  selectedPageSize,
  currentPage,
  requestBody
) {
  const user = await getUser();
  const headers = {
    Authorization: `Bearer ${user.access_token}`,
  };
  const apiUrl = `${process.env.REACT_APP_API_GATEWAY_URL}/report/api/annotation/search/${id}?pageNumber=${currentPage}&pageSize=${selectedPageSize}`;

  try {
    const response = await api.post(apiUrl, requestBody, {
      headers,
    });
    return {
      apiTableData: response.data,
      // sumOfArea: response.data.sumOfArea,
      // sumOfGrossTotal: response.data.sumOfGrossTotal,
      // sumOfLength: response.data.sumOfLength,
      // sumOfLengthTotal: response.data.sumOfLengthTotal,
      // sumOfNetTotal: response.data.sumOfNetTotal,
      // sumOfQuantity: response.data.sumOfQuantity,
    };
  } catch (error) {
    console.error("Error downloading the file:", error);
  }
}
