import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  RouterProvider,
} from "react-router-dom";
import SigninRedirect from "./pages/SigninRedirect";
import Home from "./components/home/Home";
import Protected from "./components/Protected";
import Pop from "./components/notificationMediums/Popup";
import Notification from "./components/notificationMediums/NotificationUsers";
import QuoteDetails from "./components/quoteDetails/QuoteDetails";
import Email from "./components/notificationMediums/Email";
import QuoteCreation from "./components/createQuote/QuoteCreation";
import QuoteView from "./components/quoteViewPage/QuoteViewPage";
import EditQuote from "./components/editQuote/EditQuote";
import { DspView } from "./components/editQuote/dspView/dspView";
import CreateOrganisation from "./components/createOrganisation/createOrganisation";
import CreateUser from "./components/createUser/createUser";
import ManageOrganisation from "./components/manageOrganisation/manageOrganisation";
import ManageUsers from "./components/manageUser/manageUsers";
import EditUser from "./components/editUser/editUser";
import EditOrg from "./components/editOrg/editOrg";
import TableData from "./components/table/tableData";
import ProjectCreation from "./components/project/project";
import ProjectList from "./components/projectList/projectList";
import EditProject from "./components/editProject/editProject";
import { AddressListPage } from "./components/projectList/components/addressListingPage/addressListPage";
import { PortalUser } from "./components/sidebar/portalUser/portalUser";
import { ToastContainer } from "react-toastify";
import ExternalSp from "./components/editQuote/externalServiceProviders/externalServiceProvider";
import "react-toastify/dist/ReactToastify.css";
import { SidebarProvider } from "./helperComponents/sideBar/context/sideContext";
import { JsonReport } from "./components/jsonReport/JsonReport";
import { ProjectRoleProvider } from "./context/projectRoleContext";

function App() {
  const router = createBrowserRouter(
    createRoutesFromElements(
      <>
        <Route exact path="/" element={<Protected Component={Home} />}>
          <Route
            exact
            path="/home"
            element={<Protected Component={Home} />}
          ></Route>
          <Route exact path="/email" element={<Email />}></Route>
          <Route exact path="/notification" element={<Notification />}></Route>
        </Route>
        <Route
          exact
          path="/signin-redirect"
          element={<SigninRedirect />}
        ></Route>
        <Route
          exact
          path="/pop"
          element={<Protected Component={Pop} />}
        ></Route>
        <Route exact path="/quote/:id" element={<QuoteDetails />}></Route>
        <Route
          exact
          path="/quotecreation"
          element={<Protected Component={QuoteCreation} />}
        ></Route>
        <Route
          exact
          path="/quoteview"
          element={<Protected Component={QuoteView} />}
        ></Route>
        <Route
          exact
          path="/editQuote/:id"
          element={<Protected Component={EditQuote} />}
        ></Route>
        <Route
          exact
          path="/portalUser"
          element={<Protected Component={PortalUser} />}
        ></Route>
        <Route
          exact
          path="/dspView/:id"
          element={<Protected Component={DspView} />}
        ></Route>
        <Route
          exact
          path="/externalSp"
          element={<Protected Component={ExternalSp} />}
        ></Route>
        <Route
          exact
          path="/createOrganization"
          element={<Protected Component={CreateOrganisation} />}
        ></Route>
        <Route
          exact
          path="/createUser"
          element={<Protected Component={CreateUser} />}
        ></Route>
        <Route
          exact
          path="/manageOrganization"
          element={<Protected Component={ManageOrganisation} />}
        ></Route>
        <Route
          exact
          path="/manageUser"
          element={<Protected Component={ManageUsers} />}
        ></Route>
        <Route
          exact
          path="/editUser/:id"
          element={<Protected Component={EditUser} />}
        ></Route>
        <Route
          exact
          path="/editOrg/:id"
          element={<Protected Component={EditOrg} />}
        ></Route>
        <Route
          exact
          path="/tableData"
          element={<Protected Component={TableData} />}
        ></Route>
        <Route
          exact
          path="/project"
          element={<Protected Component={ProjectCreation} />}
        ></Route>
        <Route
          exact
          path="/projectlist"
          element={
            <ProjectRoleProvider>
              <Protected Component={ProjectList} />
            </ProjectRoleProvider>
          }
        ></Route>
        <Route
          exact
          path="/addresslistPage/:id"
          element={
            <SidebarProvider>
              <Protected Component={AddressListPage} />
            </SidebarProvider>
          }
        ></Route>
        <Route
          exact
          path="/editProject/:id"
          element={<Protected Component={EditProject} />}
        ></Route>
        <Route
          exact
          path="/report/annotations/:id"
          element={<Protected Component={JsonReport} />}
        ></Route>
      </>
    )
  );

  return (
    <div className="App">
      <RouterProvider router={router}></RouterProvider>
      <ToastContainer position="bottom-right" autoClose={5000} />
    </div>
  );
}

export default App;
