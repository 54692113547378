import React from "react";
import moment from "moment";
import { DropdownFilter } from "../../../utility/dropdownFilters/ColumnFilter";
import deleteBtn from "../../../images/trash.png";

const UserTable = ({
  handleSort,
  sortedColumn,
  isAscending,
  t,
  suprmenAdmin,
  handleEditClick,
  user,
  handleDeleteUser,
  columns,
  selectedColumns,
  onToggleColumn,
  handleResetColumns,
}) => {

  const format = "DD-MM-YYYY";

  const capitalizeFirstLetter = (str) => {
    return str
      .toLowerCase()
      .replace(/(?:^|\s)\S/g, (char) => char.toUpperCase());
  };

  const getProjectStatusDisplayText = (role) => {
    const roleMapping = {
      SUPRMEN_ADMIN: "Suprmen Admin",
      ORG_ADMIN: "Organization Admin",
      REGULAR: "Regular",
      VIEWER: "Viewer",
    };
    return roleMapping[role] || role;
  };

  return (
    <div class="userlist-details-table-content">
      <div class="table-content">
        <div className="list">
          <table>
            <thead>
              <tr className="record-row">
                {selectedColumns.userName && (
                  <th
                   className="ForNameRow opac"
                    onClick={() => handleSort("name")}
                  >
                    {t("userName_table")}
                    {sortedColumn === "name" ? (isAscending ? "" : "") : ""}
                    <div class="table-arrow">
                      <div class="arrow-up">&#9650;</div>
                      <div class="arrow-down">&#9660;</div>
                    </div>
                  </th>
                )}
                {selectedColumns.email && (
                  <th
                    className="ForEmailRow opac"
                    onClick={() => handleSort("email")}
                  >
                    {t("email_table")}
                    {sortedColumn === "email" ? (isAscending ? "" : "") : ""}
                    <div class="table-arrow">
                      <div class="arrow-up">&#9650;</div>
                      <div class="arrow-down">&#9660;</div>
                    </div>
                  </th>
                )}
                {selectedColumns.startDate && (
                  <th
                    className="ForDateRow opac"
                    onClick={() => handleSort("startDate")}
                  >
                    {t("startDate_table")}
                    {sortedColumn === "startDate"
                      ? isAscending
                        ? ""
                        : ""
                      : ""}
                    <div class="table-arrow">
                      <div class="arrow-up">&#9650;</div>
                      <div class="arrow-down">&#9660;</div>
                    </div>
                  </th>
                )}
                {selectedColumns.endDate && (
                  <th
                    className="ForDateRow opac"
                    onClick={() => handleSort("endDate")}
                  >
                    {t("endDate_table")}
                    {sortedColumn === "endDate" ? (isAscending ? "" : "") : ""}
                    <div class="table-arrow">
                      <div class="arrow-up">&#9650;</div>
                      <div class="arrow-down">&#9660;</div>
                    </div>
                  </th>
                )}
                {selectedColumns.role && (
                  <th
                    className="ForRoleRow opac"
                    onClick={() => handleSort("role")}
                  >
                    {t("role_table")}
                    {sortedColumn === "role" ? (isAscending ? "" : "") : ""}
                    <div class="table-arrow">
                      <div class="arrow-up">&#9650;</div>
                      <div class="arrow-down">&#9660;</div>
                    </div>
                  </th>
                )}
                {suprmenAdmin.suprmenAdmin && selectedColumns.organisation ? (
                  <th
                    className="FororganizationRow opac"
                    onClick={() => handleSort("organization")}
                  >
                    {t("organisation_table")}
                    {sortedColumn === "organization"
                      ? isAscending
                        ? ""
                        : ""
                      : ""}
                    <div class="table-arrow">
                      <div class="arrow-up">&#9650;</div>
                      <div class="arrow-down">&#9660;</div>
                    </div>
                  </th>
                ) : null}
                {selectedColumns.status && (
                  <th
                    className="ForStatusRow opac"
                    onClick={() => handleSort("status")}
                  >
                    {t("status_table")}
                    {sortedColumn === "status" ? (isAscending ? "" : "") : ""}
                    <div class="table-arrow">
                      <div class="arrow-up">&#9650;</div>
                      <div class="arrow-down">&#9660;</div>
                    </div>
                  </th>
                )}
                <DropdownFilter
                  columns={Object.keys(selectedColumns)}
                  selectedColumns={selectedColumns}
                  onToggleColumn={onToggleColumn}
                  handleResetColumns={handleResetColumns}
                />
              </tr>
            </thead>
            <tbody>
              {user.map((user) => (
                <>
                  <tr>
                    {selectedColumns.userName && (
                      <td className="ForNameRow">
                        <a href="" onClick={() => handleEditClick(user)}>
                          {user.firstName} {user.lastName}
                        </a>
                      </td>
                    )}
                    {selectedColumns.email && (
                      <td className="ForRoleRow">{user.email}</td>
                    )}
                    {selectedColumns.startDate && (
                      <td className="ForDateRow">
                        {user.startDate
                          ? moment(user.startDate).format(format)
                          : "-"}
                      </td>
                    )}
                    {selectedColumns.endDate && (
                      <td className="ForDateRow">
                        {user.endDate
                          ? moment(user.endDate).format(format)
                          : "-"}
                      </td>
                    )}
                    {selectedColumns.role && (
                      <td className="ForRoleRow">
                        {user.role
                          ? getProjectStatusDisplayText(user.role)
                          : "-"}
                      </td>
                    )}

                    {suprmenAdmin.suprmenAdmin &&
                    selectedColumns.organisation ? (
                      <td>
                        {user.organizationName ? user.organizationName : "-"}
                      </td>
                    ) : null}
                    {selectedColumns.status && (
                      <td className="ForDateRow">
                        {capitalizeFirstLetter(user.status)}
                      </td>
                    )}
                    {selectedColumns.actions && (
                      <td className="action-buttons">
                        <div>
                          <a onClick={() => handleDeleteUser(user)}>
                            <img
                              style={{
                                opacity: user.status === "ACTIVE" ? "1" : "0.6",
                              }}
                              src={deleteBtn}
                              alt="del btn"
                            ></img>
                          </a>
                        </div>
                      </td>
                    )}
                  </tr>
                </>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default UserTable;
