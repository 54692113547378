import styles from "./PageHader.module.css";

export const PageHeader = () => {
  return (
    <div className={styles.mainHeaderContent}>
    <div className={styles.pageHeader}>
       <h1>Analyses</h1>
       <span>De Grindster</span>
    </div>
 </div>
  );
};
