import React, { useEffect, useState } from "react";
import styles from "./DataTable.module.css";
import { DropdownFilter } from "../../../../utility/dropdownFilters/ColumnFilter";
import { PaginationTool } from "../../../../utility/pagination";
import { fetchColumnPreference } from "../../Api/fetchColumnPreference";
import { postColumnPreference } from "../../Api/postColumnPreferences";
import { renderSortArrow } from "../../ui/sortingArrows";
import { GetVisualizationLink } from "../../Api/fetchOmnibaseLink";
import viewLink from "../../../../../src/images/view-link.png";
import viewLinkRedirect from "../../../../../src/images/view-linkRedirect.png";

export const DataTable = ({
  handlePageSize,
  handlePageChange,
  totalPageNumbers,
  pageNumber,
  tableData,
  sortedColumn,
  isAscending,
  handleSortWrapper,
  currentToggle,
  summaryData,
}) => {
  const [omnibaseLink, setOmnibaseLink] = useState();
  const [selectedColumns, setSelectedColumns] = useState({
    elementName: true,
    type: true,
    location: true,
    viewIn3D: true,
    length: true,
    area: true,
    quantity: true,
    lengthTotal: true,
    grossTotal: true,
    netTotal: true,
  });
  const initializeSelectedColumns = async () => {
    try {
      const initializedColumns = await fetchColumnPreference();

      const savedSettings =
        initializedColumns.apiColumnData.selectedColumns.split(",");
      const defaultColumns = {
        elementName: true,
        type: true,
        location: true,
        viewIn3D: true,
        length: true,
        area: true,
        quantity: true,
        lengthTotal: true,
        grossTotal: true,
        netTotal: true,
      };

      if (savedSettings.length > 0) {
        const updatedColumns = { ...defaultColumns };
        Object.keys(defaultColumns).forEach((column) => {
          updatedColumns[column] = savedSettings.includes(column);
        });
        return updatedColumns;
      }
      return defaultColumns;
    } catch (error) {
      console.error("Error fetching column visibility:", error);
      return {
        elementName: true,
        type: true,
        location: true,
        viewIn3D: true,
        length: true,
        area: true,
        quantity: true,
        lengthTotal: true,
        grossTotal: true,
        netTotal: true,
      };
    }
  };

  useEffect(() => {
    const fetchInitialColumns = async () => {
      const initialColumns = await initializeSelectedColumns();
      setSelectedColumns(initialColumns);
    };

    fetchInitialColumns();
  }, []);

  const saveColumnVisibility = async (updatedColumns) => {
    await postColumnPreference(updatedColumns);
  };

  const handleResetColumns = () => {
    const defaultColumns = {
      elementName: true,
      type: true,
      location: true,
      viewIn3D: true,
      length: true,
      area: true,
      quantity: true,
      lengthTotal: true,
      grossTotal: true,
      netTotal: true,
    };

    setSelectedColumns(defaultColumns);
    saveColumnVisibility(defaultColumns);
  };

  const handleToggleColumn = (column) => {
    setSelectedColumns((prevSelectedColumns) => {
      const updatedColumns = {
        ...prevSelectedColumns,
        [column]: !prevSelectedColumns[column],
      };
      saveColumnVisibility(updatedColumns);
      return updatedColumns;
    });
  };
  const getOmniBaseLink = async () => {
    const GetLink = await GetVisualizationLink();
    setOmnibaseLink(GetLink.link);
  };

  const formatNumberToEUStandard = (number) => {
    const formatter = new Intl.NumberFormat('de-DE', {
      style: 'decimal',
      minimumFractionDigits: 2, // Always show two decimal places
      maximumFractionDigits: 2,
    });
  
    return formatter.format(number);
  };

  useEffect(() => {
    getOmniBaseLink();
  }, []);
  return (
    <div className={styles.projectlistDetailsTableContent}>
      <div className={styles.tableContent}>
        <div className={styles.list}>
          <table>
            <thead>
              <tr className={styles.recordRow}>
                {selectedColumns.elementName && (
                  <th
                    className={styles.elementRow}
                    onClick={() => handleSortWrapper("elementName")}
                  >
                    Element Name {renderSortArrow("elementName")}
                  </th>
                )}
                {selectedColumns.type && (
                  <th
                    className={styles.typeRow}
                    onClick={() => handleSortWrapper("type")}
                  >
                    Type {renderSortArrow("type", isAscending, sortedColumn)}
                  </th>
                )}
                {selectedColumns.location && (
                  <th
                    className={styles.locationRow}
                    onClick={() => handleSortWrapper("location")}
                  >
                    Location{" "}
                    {renderSortArrow("location", isAscending, sortedColumn)}
                  </th>
                )}
                {selectedColumns.viewIn3D && (
                  <th className={styles.viewRow}>View in 3D</th>
                )}

                {selectedColumns.length && (
                  <th
                    className={styles.lengthRow}
                    onClick={() => handleSortWrapper("length")}
                  >
                    Length (m<sup>1</sup>)
                    {renderSortArrow("length", isAscending, sortedColumn)}
                  </th>
                )}

                {selectedColumns.area && (
                  <th
                    className={styles.areaRow}
                    onClick={() => handleSortWrapper("area")}
                  >
                    Area (m<sup>2</sup>){" "}
                    {renderSortArrow("area", isAscending, sortedColumn)}
                  </th>
                )}

                {selectedColumns.quantity && (
                  <th
                    className={styles.quantityRow}
                    onClick={() => handleSortWrapper("quantity")}
                  >
                    Quantity
                    {renderSortArrow("quantity", isAscending, sortedColumn)}
                  </th>
                )}
                {selectedColumns.lengthTotal && (
                  <th
                    className={styles.lengthTotalRow}
                    onClick={() => handleSortWrapper("lengthTotal")}
                  >
                    Length Total (m<sup>1</sup>)
                    {renderSortArrow("lengthTotal", isAscending, sortedColumn)}
                  </th>
                )}
                {selectedColumns.grossTotal && (
                  <th
                    className={styles.grossRow}
                    onClick={() => handleSortWrapper("grossTotal")}
                  >
                    Gross Total (m<sup>2</sup>)
                    {renderSortArrow("grossTotal", isAscending, sortedColumn)}
                  </th>
                )}
                {selectedColumns.netTotal && (
                  <th
                    className={styles.netRow}
                    onClick={() => handleSortWrapper("netTotal")}
                  >
                    Net Total (m<sup>2</sup>)
                    {renderSortArrow("netTotal", isAscending, sortedColumn)}
                  </th>
                )}
                <DropdownFilter
                  columns={Object.keys(selectedColumns)}
                  selectedColumns={selectedColumns}
                  onToggleColumn={handleToggleColumn}
                  handleResetColumns={handleResetColumns}
                />
              </tr>
            </thead>
            <tbody>
              {tableData && tableData.length > 0 ? (
                tableData.map((item) => (
                  <tr>
                    {selectedColumns.elementName && (
                      <td className={styles.elementRow}>{item.elementName}</td>
                    )}
                    {selectedColumns.type && (
                      <td className={styles.typeRow}>{item.type}</td>
                    )}
                    {selectedColumns.location && (
                      <td className={styles.locationRow}>{item.location}</td>
                    )}
                    {selectedColumns.viewIn3D && (
                      <td className={styles.viewRow}>
                        <a
                          href={`${omnibaseLink}/projects/${item.omniBaseProjectId}/view?org_uuid=${item.omniBaseOrgId}`}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <img src={viewLink} alt="view Link" />
                          <img
                            src={viewLinkRedirect}
                            alt="view Link Redirect"
                          />
                        </a>
                      </td>
                    )}
                    {selectedColumns.length && (
                      <td className={styles.lengthRow}>
                        {" "}
                        {isNaN(Number(item.length))
                          ? "N/A"
                          : formatNumberToEUStandard(item.length)}
                      </td>
                    )}
                    {selectedColumns.area && (
                      <td className={styles.areaRow}>
                        {isNaN(Number(item.area))
                          ? "N/A"
                          : formatNumberToEUStandard(item.area)}
                      </td>
                    )}
                    {selectedColumns.quantity && (
                      <td className={styles.quantityRow}>
                        {isNaN(Number(item.quantity))
                          ? "N/A"
                          : formatNumberToEUStandard(item.quantity)}
                      </td>
                    )}
                    {selectedColumns.lengthTotal && (
                      <td className={styles.lengthTotalRow}>
                        {isNaN(Number(item.lengthTotal))
                          ? "N/A"
                          : formatNumberToEUStandard(item.lengthTotal)}
                      </td>
                    )}
                    {selectedColumns.grossTotal && (
                      <td className={styles.grossRow}>
                        {isNaN(Number(item.grossTotal))
                          ? "N/A"
                          : formatNumberToEUStandard(item.grossTotal)}
                      </td>
                    )}
                    {selectedColumns.netTotal && (
                      <td className={styles.netRow}>
                        {isNaN(Number(item.netTotal))
                          ? "N/A"
                          : formatNumberToEUStandard(item.netTotal)}
                      </td>
                    )}
                    <td></td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="5">No data available</td>
                </tr>
              )}
              {currentToggle === true ? (
                <tr className={styles.showTotalRow}>
                  {selectedColumns.elementName && <td>Total</td>}
                  {selectedColumns.type && <td> </td>}
                  {selectedColumns.location && <td></td>}
                  {selectedColumns.viewIn3D && <td></td>}
                  {selectedColumns.length && (
                    <td className={styles.showTotalItem}>
                      {formatNumberToEUStandard(summaryData.sumOfLength)}
                    </td>
                  )}
                  {selectedColumns.area && (
                    <td className={styles.showTotalItem}>
                      {formatNumberToEUStandard(summaryData.sumOfArea)}
                    </td>
                  )}
                  {selectedColumns.quantity && (
                    <td className={styles.showTotalItem}>
                      {formatNumberToEUStandard(summaryData.sumOfQuantity)}
                    </td>
                  )}
                  {selectedColumns.lengthTotal && (
                    <td className={styles.showTotalItem}>
                      {formatNumberToEUStandard(summaryData.sumOfLengthTotal)}
                    </td>
                  )}
                  {selectedColumns.grossTotal && (
                    <td className={styles.showTotalItem}>
                      {formatNumberToEUStandard(summaryData.sumOfGrossTotal)}
                    </td>
                  )}
                  {selectedColumns.netTotal && (
                    <td className={styles.showTotalItem}>
                      {formatNumberToEUStandard(summaryData.sumOfNetTotal)}
                    </td>
                  )}

                  <td></td>
                </tr>
              ) : null}
            </tbody>
          </table>
        </div>
      </div>
      <PaginationTool
        pageNumbers={totalPageNumbers}
        pageNumber={pageNumber}
        handlePageChange={handlePageChange}
        handlePageSize={handlePageSize}
      />
    </div>
  );
};
