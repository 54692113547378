import { getUser } from "../../../helper/auth_helper";
import api from "../../../helper/auth_interceptor";

export const handleDataSort = async (
  id,
  column,
  sortedColumn,
  isAscending,
  setSortedColumn,
  setIsAscending,
  currentPage,
  selectedPageSize,
  requestBody,
  setTableData,
  setTotalPageNumbers
) => {
  const user = await getUser();
  const pageForUrl = currentPage;
  const selectedSize = selectedPageSize;
  const newIsAscending = column === sortedColumn ? !isAscending : true;
  setSortedColumn(column);
  setIsAscending(newIsAscending);

  const sortOrder = newIsAscending ? "asc" : "desc";

  const sortApi = `${process.env.REACT_APP_API_GATEWAY_URL}/report/api/annotation/search/${id}?pageNumber=${pageForUrl}&pageSize=${selectedSize}&sortBy=${column}&sortOrder=${sortOrder}`;
  const headers = {
    Authorization: `Bearer ${user.access_token}`,
  };

  try {
    const response = await api.post(sortApi, requestBody, {
      headers,
    });
    console.log(response.data.data);
    setTableData(response.data.data);
    setTotalPageNumbers(response.data.totalPages);
  } catch (error) {
    console.error("Error fetching data:", error);
  }
};
