import React, { useState, useEffect, useRef, useCallback } from "react";
import styles from "./Filters.module.css";
import searchbar from "../../../../images/search-bar.png";
import { FilterDropdown } from "../../../../utility/dropdownFilters/elementFilterDopdowns";
import { fetchElementFilterData } from "../../Api/fetchElementFilterData";
import { fetchTypeFilterData } from "../../Api/fetchTypeFilterData";
import { fetchLocationFilterData } from "../../Api/fetchLocationFilterData";
import { TypeFilterDropdown } from "../../../../utility/dropdownFilters/typeFilterDropdown";
import { LocationFilterDropdown } from "../../../../utility/dropdownFilters/locationFilterDropdown";
import { ToggleButton } from "../../../commonUI/toggleButton/toggleButton";

export const TableFilters = ({
  requestBody,
  handleSearchFormSubmit,
  buildingId,
  handleElementFilterSearch,
  handleTypeFilterSearch,
  handleLocationFilterSearch,
  resetFilter,
  fetchTableData,
  exportTableData,
  setCurrentToggle,
}) => {
  const dropdownRef = useRef(null);

  const ElementfilterRequestBody = useRef({
    searchText: null,
  });

  const TypefilterRequestBody = useRef({
    searchText: null,
  });

  const LocationfilterRequestBody = useRef({
    searchText: null,
  });

  const [isElementOpen, setIsElementOpen] = useState(false);
  const [istypeOpen, setIsTypeOpen] = useState(false);
  const [isLocationOpen, setIsLocationOpen] = useState(false);
  const [allReset, setAllReset] = useState(false);
  const [elementData, setElementData] = useState([]);
  const [typeData, setTypeData] = useState([]);
  const [locationData, setLocationData] = useState([]);
  const [isChecked, setIsChecked] = useState(false);

  const handleToggle = (event) => {
    const checked = event.target.checked;
    setIsChecked(checked);
    setCurrentToggle(checked);
  };

  const selectedElementColumns = elementData.reduce((acc, item) => {
    if (item !== "" && item !== null) {
      acc[item] = false;
    }
    return acc;
  }, {});

  const selectedTypeColumns = typeData.reduce((acc, item) => {
    if (item !== "" && item !== null) {
      acc[item] = false;
    }
    return acc;
  }, {});

  const selectedLocationColumns = locationData.reduce((acc, item) => {
    if (item !== "" && item !== null) {
      acc[item] = false;
    }
    return acc;
  }, {});

  const fetchElementFilter = useCallback(async () => {
    const ElementData = await fetchElementFilterData(
      buildingId,
      ElementfilterRequestBody.current
    );
    setElementData(ElementData.apiElementData);
  }, [buildingId]);

  const fetchTypeFilter = useCallback(async () => {
    const TypeData = await fetchTypeFilterData(
      buildingId,
      TypefilterRequestBody.current
    );
    setTypeData(TypeData.apiTypeData);
  }, [buildingId]);

  const fetchLocationFilter = useCallback(async () => {
    const LocationData = await fetchLocationFilterData(
      buildingId,
      LocationfilterRequestBody.current
    );
    setLocationData(LocationData.apiLocationData);
  }, [buildingId]);

  const toggleElementDropdown = () => {
    setIsElementOpen((prev) => !prev);
  };
  const toggleTypeDropdown = () => {
    setIsTypeOpen((prev) => !prev);
  };
  const toggleLocationDropdown = () => {
    setIsLocationOpen((prev) => !prev);
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsElementOpen(false);
      setIsTypeOpen(false);
      setIsLocationOpen(false);
    }
  };

  const handleSearchElementFilter = (event) => {
    const { value } = event.target;
    ElementfilterRequestBody.current = {
      ...ElementfilterRequestBody.current,
      searchText: value,
    };
    fetchElementFilter();
  };

  const handleSearchTypeFilter = (event) => {
    const { value } = event.target;
    TypefilterRequestBody.current = {
      ...TypefilterRequestBody.current,
      searchText: value,
    };
    fetchTypeFilter();
  };

  const handleSearchLocation = (event) => {
    const { value } = event.target;
    LocationfilterRequestBody.current = {
      ...LocationfilterRequestBody.current,
      searchText: value,
    };
    fetchLocationFilter();
  };

  const ResetFilter = (filterName) => {
    if (filterName === "element") {
      ElementfilterRequestBody.current = {
        ...ElementfilterRequestBody.current,
        searchText: "",
      };
      fetchElementFilter();
    } else if (filterName === "type") {
      ElementfilterRequestBody.current = {
        ...ElementfilterRequestBody.current,
        searchText: "",
      };
      fetchTypeFilter();
    } else if (filterName === "lcoation") {
      LocationfilterRequestBody.current = {
        ...LocationfilterRequestBody.current,
        searchText: "",
      };
      fetchLocationFilter();
    } else {
      ElementfilterRequestBody.current = {
        ...ElementfilterRequestBody.current,
        searchText: "",
      };
      TypefilterRequestBody.current = {
        ...TypefilterRequestBody.current,
        searchText: "",
      };
      LocationfilterRequestBody.current = {
        ...LocationfilterRequestBody.current,
        searchText: "",
      };
      fetchElementFilter();
      fetchTypeFilter();
      fetchLocationFilter();
    }
  };

  const ResetAll = () => {
    requestBody.current = {
      ...requestBody.current,
      searchText: null,
      elementNames: null,
      types: null,
      locations: null,
    };
    setAllReset((prev) => !prev);
    fetchTableData();
    setIsElementOpen(false);
    setIsLocationOpen(false);
    setIsTypeOpen(false);
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    fetchElementFilter();
    fetchTypeFilter();
    fetchLocationFilter();
  }, [fetchElementFilter, fetchTypeFilter, fetchLocationFilter]);

  return (
    <div ref={dropdownRef} className={styles.jsonlistDetailsContent}>
      <div className={styles.contentOptions}>
        <div className={styles.searchOptionSearchfilter}>
          <div className={styles.searchInput}>
            <img src={searchbar} alt="home icon" />
            <input
              className={styles.checkInputProjectList}
              type="text"
              placeholder="Search"
              value={requestBody.current.searchText || ""}
              onChange={handleSearchFormSubmit}
            />
          </div>
        </div>
        <div className={styles.mainColumnSelection}>
          <button
            className={isElementOpen ? styles.activeBtn : styles.filterBtn}
            onClick={() => toggleElementDropdown()}
          >
            <span>Element Name</span>
          </button>

          <FilterDropdown
            isDropdownOpen={isElementOpen}
            columns={Object.keys(selectedElementColumns)}
            selectedColumns={selectedElementColumns}
            handleSearchElementFilter={handleSearchElementFilter}
            ElementfilterRequestBody={ElementfilterRequestBody}
            ResetFilter={ResetFilter}
            handleElementFilterSearch={handleElementFilterSearch}
            resetFilter={resetFilter}
            allReset={allReset}
            setAllReset={setAllReset}
          />
        </div>
        <div className={styles.typeMainDiv}>
          <button
            className={istypeOpen ? styles.activeBtn : styles.filterBtn}
            onClick={() => toggleTypeDropdown()}
          >
            <span>Type</span>
          </button>

          <TypeFilterDropdown
            isDropdownOpen={istypeOpen}
            columns={Object.keys(selectedTypeColumns)}
            selectedColumns={selectedTypeColumns}
            handleSearchTypeFilter={handleSearchTypeFilter}
            TypefilterRequestBody={TypefilterRequestBody}
            ResetFilter={ResetFilter}
            handleTypeFilterSearch={handleTypeFilterSearch}
            resetFilter={resetFilter}
            allReset={allReset}
            setAllReset={setAllReset}
          />
        </div>
        <div className={styles.locationMainDiv}>
          <button
            className={isLocationOpen ? styles.activeBtn : styles.filterBtn}
            onClick={() => toggleLocationDropdown()}
          >
            <span>Location</span>
          </button>

          <LocationFilterDropdown
            isDropdownOpen={isLocationOpen}
            columns={Object.keys(selectedLocationColumns)}
            selectedColumns={selectedLocationColumns}
            handleSearchLocation={handleSearchLocation}
            LocationfilterRequestBody={LocationfilterRequestBody}
            ResetFilter={ResetFilter}
            handleLocationFilterSearch={handleLocationFilterSearch}
            resetFilter={resetFilter}
            allReset={allReset}
            setAllReset={setAllReset}
          />
        </div>
        <div className={styles.resetMainDiv}>
          <button className={styles.jsonFilterBtn} onClick={ResetAll}>
            <span>Reset Filter</span>
          </button>
        </div>
      </div>
      <div className={styles.rightButtons}>
        <div className={styles.jsonExportBtn}>
          <button
            className={styles.btnQuoteBtns}
            onClick={exportTableData}
            type="button"
          >
            <a className={styles.quoteLink}>
              <span>Export CSV</span>
            </a>
          </button>
        </div>
        <div className={styles.switch}>
          <ToggleButton isChecked={isChecked} handleToggle={handleToggle} />
        </div>

        <div className={styles.totalResult}>
          <h6>Show totals</h6>
        </div>
      </div>
    </div>
  );
};
