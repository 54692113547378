export const renderSortArrow = (column, isAscending, sortedColumn) => {
  const isSorted = sortedColumn === column;
  return (
    <div className="table-arrow">
      <div className={`arrow-up ${isSorted && isAscending ? "active" : ""}`}>
        &#9650;
      </div>
      <div className={`arrow-down ${isSorted && !isAscending ? "active" : ""}`}>
        &#9660;
      </div>
    </div>
  );
};
